import { render, staticRenderFns } from "./form.vue?vue&type=template&id=0d8ef9d7&scoped=true"
import script from "./form.vue?vue&type=script&lang=js"
export * from "./form.vue?vue&type=script&lang=js"
import style0 from "./form.vue?vue&type=style&index=0&id=0d8ef9d7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d8ef9d7",
  null
  
)

export default component.exports